<template>
  <BaseInput
    ref="placeInput"
    class="place-input"
    :placeholder="$t('navbar.location.placeholder')"
    icon="place"
    @focus="useGooglePaces"
  />
</template>

<script setup lang="ts">
import { BaseInput } from "#components";

const location = useLocationState();
const placeInput = ref<InstanceType<typeof BaseInput>>();

const host = useCurrentHost();

const useGooglePaces = async () => {
  // Add the autocomplete to the places input
  const google = await useGoogleMaps();

  const autocomplete = new google.maps.places.Autocomplete(
    placeInput.value!.$refs.inputElement as HTMLInputElement,
    {
      componentRestrictions: {
        country: host.value === "allefolders" ? "nl" : "be",
      },
    }
  );

  autocomplete.addListener("place_changed", () => {
    const place = autocomplete.getPlace();
    const loc = place.geometry?.location;

    if (!loc) return;

    location.value = {
      lat: loc.lat(),
      long: loc.lng(),
    };
  });
};
</script>

<style lang="scss" scoped>
.place-input {
  height: 4.25rem;
}
</style>
