export default (): Promise<typeof window.google> => {
  return new Promise((resolve, reject) => {
    if (process.server) {
      reject(new Error("Google Maps cannot be loaded on the server"));
    }

    if (window.google) resolve(window.google);

    const { googleApiKey } = useRuntimeConfig().public;
    useScriptTag(
      `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places&callback=Function.prototype`,
      // on script tag loaded.
      () => {
        resolve(window.google);
      }
    );
  });
};
